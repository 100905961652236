var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-carousel',{staticStyle:{"opacity":"0.9","border-radius":"20px"},attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 270 : 500,"cycle":"","dark":"","show-arrows-on-hover":"","reverse-transition":"fade-transition","transition":"fade-transition"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.backgrounds),function(info,i){return _c('v-carousel-item',{key:`carrousel-${i}`,attrs:{"src":_vm.imageUrl(info)}},[(info.background_url === '' && info.background === '')?_c('v-sheet',{style:({
          display: 'flex',
          'flex-direction': 'column',
          'justify-content': 'center',
          'align-items': 'center'
        }),attrs:{"color":info.background_color,"height":"100%","tile":""}},[_c('div',{staticClass:"pa-4 text-h3",style:({
            color: info.title_color
          })},[_vm._v(" "+_vm._s(info.title)+" ")]),_c('div',{staticClass:"text-h5",style:({
            color: info.msg_color
          })},[_vm._v(" "+_vm._s(info.msg)+" ")])]):_c('div',{style:({
          height: '100%',
          display: 'flex',
          'flex-direction': 'column',
          'justify-content': 'center',
          'align-items': 'center'
        })},[_c('v-sheet',{style:({
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center'
          }),attrs:{"color":info.background_color,"tile":""}},[_c('div',{staticClass:"pa-2 text-h3",style:({
              color: info.title_color
            })},[_vm._v(" "+_vm._s(info.title)+" ")]),_c('div',{staticClass:"text-h5",style:({
              color: info.msg_color
            })},[_vm._v(" "+_vm._s(info.msg)+" ")])])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }