<template>
  <div>
    <v-carousel
      v-model="model"
      style="opacity: 0.9; border-radius: 20px"
      :height="$vuetify.breakpoint.smAndDown ? 270 : 500"
      cycle
      dark
      show-arrows-on-hover
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
      <v-carousel-item
        v-for="(info, i) in backgrounds"
        :key="`carrousel-${i}`"
        :src="imageUrl(info)"
      >
        <v-sheet
          v-if="info.background_url === '' && info.background === ''"
          :style="{
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center'
          }"
          :color="info.background_color"
          height="100%"
          tile
        >
          <div
            class="pa-4 text-h3"
            :style="{
              color: info.title_color
            }"
          >
            {{ info.title }}
          </div>
          <div
            class="text-h5"
            :style="{
              color: info.msg_color
            }"
          >
            {{ info.msg }}
          </div>
        </v-sheet>
        <div
          v-else
          :style="{
            height: '100%',
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center'
          }"
        >
          <v-sheet
            :style="{
              display: 'flex',
              'flex-direction': 'column',
              'justify-content': 'center',
              'align-items': 'center'
            }"
            :color="info.background_color"
            tile
          >
            <div
              class="pa-2 text-h3"
              :style="{
                color: info.title_color
              }"
            >
              {{ info.title }}
            </div>
            <div
              class="text-h5"
              :style="{
                color: info.msg_color
              }"
            >
              {{ info.msg }}
            </div>
          </v-sheet>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Array, Object]
    }
  },
  data() {
    return {
      model: 0
    }
  },
  methods: {
    imageUrl(info) {
      return info.background !== '' ? info.background : info.background_url
    }
  },
  computed: {
    backgrounds() {
      return !Array.isArray(this.value)
        ? []
        : this.value.map((element) => ({
            ...element,
            background:
              element.background === '' || element.background === null
                ? ''
                : element.background_img !== undefined
                ? this.showImage(element.background_img)
                : this.showImage(element.background)
          }))
    }
  }
}
</script>
