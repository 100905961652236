<template>
  <v-container
    fluid
    class="loginContainer pa-0"
    :style="{
      'background-image': 'url(https://picsum.photos/1024/768?random)'
    }"
  >
    <v-row no-gutters>
      <v-col order="2" order-md="1" cols="12" md="6">
        <v-card class="infocard elevation-3">
          <div class="text-right">
            <i-btn
              :small="$vuetify.breakpoint.xsOnly"
              text
              @click="changeMode"
              :title="activeTab"
              color="primary"
              :icon="`far fa-${
                activeTab === 'banners' ? 'image' : 'lightbulb'
              }`"
            />
            <i-locale login />
          </div>
          <v-card-title class="d-flex flex-column-reverse justify-center">
            <img
              :src="img"
              alt="Logo"
              :style="{
                height: $vuetify.breakpoint.mdAndUp ? '8rem' : '4rem'
              }"
            />
            <span class="ml-4" v-if="company !== undefined">
              {{ company.name }}
            </span>
          </v-card-title>
          <v-card-text>
            <ValidationObserver v-slot="{ handleSubmit, reset }">
              <form
                v-if="mode === 1"
                @submit.prevent="handleSubmit(setpassword)"
                @reset.prevent="reset"
              >
                <v-row no-gutters>
                  <v-col cols="12" align="center">
                    <ValidationProvider
                      vid="password"
                      :name="$tc('password', 1)"
                      :rules="'required|min:8'"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        autocomplete="off"
                        outlined
                        v-model="user.new_password1"
                        :error-messages="errors[0]"
                        :label="$tc('password', 1)"
                        @click:append="showPass = !showPass"
                        :type="showPass ? 'text' : 'password'"
                        :append-icon="showPass ? 'fa-eye' : 'fa-eye-slash'"
                        key="password-input"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" align="center">
                    <ValidationProvider
                      vid="password_confirm"
                      :name="$tc('password', 2)"
                      :rules="'required|min:8|confirmed:password'"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        autocomplete="off"
                        outlined
                        v-model="user.new_password2"
                        :error-messages="errors[0]"
                        :label="$tc('password', 2)"
                        @click:append="showPass = !showPass"
                        :type="showPass ? 'text' : 'password'"
                        :append-icon="showPass ? 'fa-eye' : 'fa-eye-slash'"
                        key="secondary-input"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" align="center">
                    <v-btn color="primary" :loading="load" type="submit">
                      <v-icon v-text="'fa-unlock-alt'" class="pr-2" />
                      {{ $tc('restart', 1) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        order="1"
        order-md="2"
        cols="12"
        md="6"
        justify="center"
        align-self="center"
        class="px-2"
      >
        <carrousel-tips v-if="tips.length > 0" v-model="tips" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import CarrouselTips from '../../components/carrouselTips/CarrouselTips.vue'
export default {
  components: { CarrouselTips },
  data() {
    return {
      tips: [],
      user: {
        new_password1: '',
        new_password2: ''
      },
      img: '',
      showPass: false,
      mode: 1,
      load: false,
      activeTab: 'banners',
      uidb64: null,
      token: null
    }
  },
  watch: {
    async logo(val) {
      if (val !== undefined && val !== null) {
        this.img = await this.showImage(val)
      } else {
        this.img = require('@/assets/logo-blue.png')
      }
    }
  },
  computed: {
    ...mapGetters({
      logo: 'company/getLogo',
      company: 'company/getCompanyData'
    })
  },
  async mounted() {
    this.$vuetify.theme.dark = false
    const currentUrl = window.location.href
    const urlParams = new URLSearchParams(new URL(currentUrl).search)
    this.uidb64 = urlParams.get('uidb64')
    this.token = urlParams.get('token')
    this.getCarrousel()
    if (this.logo !== undefined && this.logo !== null) {
      this.img = await this.showImage(this.logo)
    } else {
      this.img = require('@/assets/logo-blue.png')
    }
  },
  methods: {
    changeMode() {
      switch (this.activeTab) {
        case 'banners':
          this.activeTab = 'tips'
          break
        default:
          this.activeTab = 'banners'
          break
      }
      this.getCarrousel()
    },
    async getCarrousel() {
      try {
        this.loading = true
        let response = await this.$api.loginCarrousel.list({
          opt: { params: { active: true, mode: this.activeTab } }
        })
        this.tips = response.data
      } finally {
        this.loading = false
      }
    },
    setpassword() {
      this.load = true
      this.$api.auth
        .forgotPassword({
          form: this.user,
          opt: { params: { uidb64: this.uidb64, token: this.token } }
        })
        .then((response) => {
          this.$toast.success(`${this.$tc('change', 2)}`)
          var self = this
          setTimeout(function () {
            self.$router.push('index')
          }, 3000)
          this.load = false
        })
        .catch((err) => {
          this.load = false
        })
    }
  }
}
</script>
